<template>
  <section>
    <b-row>
      <b-col cols="12">
        <b-card>
          <div class="m-2">
            <b-row class="justify-content-end">
              <b-col
                cols="3"
                md="3"
                class="
                  d-flex
                  align-items-center
                  justify-content-start
                  mb-1 mb-md-0
                "
              >
                <label>Search By</label>
                <b-form-select
                  label-field=""
                  :options="search_option"
                  v-model="search_by"
                  @input="onSelectSearchBy"
                />
              </b-col>
              <b-col cols="12" md="6">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    :placeholder="$t('Common.Search')"
                    class="d-inline-block mr-1"
                    v-model="search"
                    @input="OnSearch"
                  />
                </b-input-group>
              </b-col>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="w-100"
                  @click="
                    () => {
                      $router.push('/food/recipe-main-form/new');
                    }
                  "
                  v-if="$can('write', 'food')"
                >
                  {{ $t("RecipeMainMaster.AddRecipe") }}
                </b-button>
              </b-col>
            </b-row>
          </div>

          <div class="box mt-2">
            <b-table
              striped
              hover
              responsive
              class="position-relative"
              primary-key="id"
              empty-text="No matching records found"
              show-empty
              :per-page="pagination.perPage"
              :current-page="pagination.currentPage"
              :items="allRecipeItemLists"
              :fields="fields"
            >
              <template #head()="data">{{ $t(data.label) }}</template>
              <template #cell(recipe_is_active)="data">
                <b-form-checkbox
                  name="check-button"
                  switch
                  inline
                  :checked="data.item.recipe_is_active == 1 ? true : false"
                  @change="activateRecipe(data.item)"
                  v-if="$can('write', 'food')"
                />
                <span v-else>
                  <span
                    :class="
                      data.item.recipe_is_active
                        ? 'badge text-capitalize badge-light-success badge-pill'
                        : 'badge text-capitalize badge-light-danger badge-pill'
                    "
                    >{{
                      data.item.recipe_is_active ? "Active" : "In-Active"
                    }}</span
                  >
                  <!-- {{ data.item.mrecipe_is_active == 1 ? "Active" : "Inactive" }} -->
                </span>
              </template>
              <template #cell(edit)="data">
                <feather-icon
                  icon="EditIcon"
                  @click="
                    () => {
                      $router.push(
                        `/food/recipe-main-form/${data.item.recipe_id}`
                      );
                    }
                  "
                  v-if="$can('write', 'food')"
                />
                <feather-icon
                  v-else
                  icon="EyeIcon"
                  @click="
                    () => {
                      $router.push(
                        `/food/recipe-main-form/${data.item.recipe_id}`
                      );
                    }
                  "
                />
              </template>
            </b-table>
          </div>

          <b-card class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="allRecipeItemLists.length"
                :per-page="pagination.perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BInputGroupPrepend,
  BCard,
  BTable,
  BInputGroupAppend,
  BButton,
  BButtonGroup,
  BDropdown,
  BRow,
  BCol,
  BContainer,
  BFormGroup,
  BPagination,
  BInputGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FoodServices from "@/apiServices/FoodServices";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    FeatherIcon,
    BInputGroupPrepend,
    BCard,
    BTable,
    BInputGroupAppend,
    BButton,
    BButtonGroup,
    BDropdown,
    BRow,
    BCol,
    BContainer,
    BFormGroup,
    BPagination,
    BInputGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BFormSelect,
  },
  data() {
    return {
      pagination: {
        currentPage: 1,
        perPage: 50,
      },
      fields: [
        {
          key: "recipe_id",
          label: "RecipeMainMaster.RECIPEID",
          sortable: true,
        },
        {
          key: "recipe_name",
          label: "RecipeMainMaster.RECIPENAME",
          sortable: true,
        },
        {
          key: "recipe_code",
          label: "RecipeMainMaster.RECIPE CODE",
          sortable: true,
        },
        // {
        //   key: "ingredient_codes",
        //   label: "IngredientMainMaster.INGREDIENTCODE",
        //   sortable: true,
        // },
        {
          key: "recipe_is_active",
          label: "Common.Status",
        },
        {
          key: "edit",
          label: "Common.Edit",
        },
      ],
      allRecipeItemLists: [],
      search_option: ["recipe", "ingredients"],
      search_by: "recipe",
      search: "",
      searchTimeout: null,
    };
  },
  computed: {
    // RecipeLists: function () {
    //   let arr = this.allRecipeItemLists;
    //   let search = this.search;
    //   if (search) {
    //     search = search.toLowerCase();
    //     arr = arr.filter((item, index) => {
    //       if (this.search_by === "recipe") {
    //         let recipe_name = item.recipe_name;
    //         let recipe_code = item.recipe_code;
    //         recipe_name = recipe_name.toLowerCase();
    //         if (
    //           (recipe_name && recipe_name.includes(search)) ||
    //           (recipe_code && recipe_code.toLowerCase().includes(search))
    //         ) {
    //           return true;
    //         }
    //       } else {
    //         let ingredient_names = item.ingredient_names;
    //         let ingredient_codes = item.ingredient_codes;
    //         if (!ingredient_codes || !ingredient_names) return false;
    //         ingredient_names = ingredient_names.toLowerCase();
    //         if (
    //           (ingredient_names && ingredient_names.includes(search)) ||
    //           (ingredient_codes &&
    //             ingredient_codes.toLowerCase().includes(search))
    //         ) {
    //           console.log("in");
    //           return true;
    //         }
    //       }
    //       return false;
    //     });
    //     this.pagination.currentPage = 1;
    //   }
    //   return arr;
    // },
  },
  methods: {
    onSelectSearchBy(val) {
      if (val === "ingredients") {
        this.fields.splice(3, 0, {
          key: "ingredient_codes",
          label: "IngredientMainMaster.INGREDIENTCODE",
          sortable: true,
        });
      } else {
        this.fields.splice(3, 1);
      }
    },
    OnSearch() {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getAllRecipeList();
      }, 500);
    },
    getAllRecipeList() {

      FoodServices.getAllRecipeMain({
        search: this.search,
        search_by: this.search_by,
      })
        .then((response) => {
          if (response.data.status) {
            this.allRecipeItemLists = response.data.data;
            // console.log(this.allRecipeItemLists);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || "Validation Failed",
                icon: response.data.icon,
                variant: response.data.variant,
              },
            });
          }
        })
        .catch((error) => {
          console.log("Fetching Recipe Master ", error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Server Error",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        });
    },
    activateRecipe(item) {
      let paylod = {
        recipe_id: item.recipe_id,
        status: item.recipe_is_active ? false : true,
      };
      FoodServices.setMainRecipeStatus(paylod)
        .then((response) => {
          if (response.data.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Recipe Status is Updated SuccessFully.",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.getAllRecipeList();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || "Validation Failed",
                icon: response.data.icon,
                variant: response.data.variant,
              },
            });
          }
        })
        .catch((error) => {
          console.log("Fetching Recipe Master ", error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Server Error",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        });
    },
  },
  beforeMount() {
    this.getAllRecipeList();
  },
};
</script>
